import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {masking, unmasking, IncomeMask} from '../../../services/masking'
import {  useFormContext } from "react-hook-form";
import IncomeCalculator from "./incomeCalculator";
import moment from "moment";
import SpouseForm from "../add-ons/SpouseForm";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Parser from 'react-html-parser';
import AudioEyeSupport from "../../../services/audioEyeSupport";
import AxiosWrapper from "../../../services/axios";
import AdobeScript from "../../../services/adobeScript";

function UserDataForm() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const formFields = useStoreState(state => state.siteSettingsModel.formFields);
    const info = useStoreState((state) => state.applicantModel.applicant);
    const setInfo = useStoreActions(actions => actions.applicantModel.setApplicant);
    const text = useStoreState((state) => state.dmText.terms);
    const [cursor, setCursorPosition] = useState(1)
    const {  register, formState: { errors }, unregister, clearErrors } = useFormContext();
    const ada = new AudioEyeSupport()
    const getFieldData = (fieldName) => formFields.find(field => field.fieldName === fieldName);
    const checkIfFieldIsActive = (fieldName) => getFieldData(fieldName)?.isActive;
    const axios = new AxiosWrapper();
    const adobe = new AdobeScript();

    useEffect(() => {
        setTimeout(() => {

            setInfo({index : "FirstName",   value : axios.findParam("firstname") || info.FirstName})
            setInfo({index : "MiddleName",  value : axios.findParam("middleinitial") || info.MiddleName})
            setInfo({index : "Suffix",      value : (axios.findParam("suffix")) ? axios.findParam("suffix").toUpperCase() : info.Suffix})
            setInfo({index : "LastName",    value : axios.findParam("lastname") || info.LastName})
            setInfo({index : "Address1",    value : axios.findParam("address1") || info.Address1})
            setInfo({index : "Address2",    value : axios.findParam("address2") || info.Address2})
            setInfo({index : "State",       value : axios.findParam("state") || info.State})
            setInfo({index : "City",        value : axios.findParam("city") || info.City})
            setInfo({index : "Email",       value : axios.findParam("email") || info.Email})
            setInfo({index : "Zip",         value : axios.findParam("zip") || info.Zip})

            if( axios.findParam("hascheckingaccount")) {
                setInfo({index : "HasCheckingAccount", value : "true"})
            }
           

        },1000)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        //unregister controls to avoid validations
        if (info.hasAdditionalCardholder==="false") {
            unregister(["additionalCardholderFirstName", "additionalCardholderMI", "additionalCardholderLastName"]);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [info.hasAdditionalCardholder]);

    const clearMonthlyIncomeError = () => {
        clearErrors("MonthlyIncome");
    };

    const [localIncomeSource, setLocalIncomeSource] = useState(info.IncomeSource);

    useEffect(() => {
        setLocalIncomeSource(info.IncomeSource);
    }, [info.IncomeSource]);
    

    const handleDropdownChange = (e) => {
        const value = e.target.value;
        setLocalIncomeSource(value);
        setInfo({ index: "IncomeSource", value });
    };

    return (
        <div className="col-12 p-0">
         
                    <div className="row">
                        <div className="col-lg-4 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="FirstName">First Name</label>
                                <div className="input-container">
                                    <input 
                                    maxLength={17} 
                                    readOnly={Setting.mainSettings.Flow === "DM"} 
                                    value={info.FirstName} 
                                    onFocus={() => {ada.sendDYEvent({ name : 'FirstNameFocusEvent'}); adobe.event('form_start')}}
                                    onInput={(e) => {
                                      
                                        if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                            if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                if((e.target.value.split(" ").length - 1) < 2){
                                                    setInfo({ index: "FirstName", value: e.target.value.trimLeft() })}
                                                }
                                            }
                                        }
                                    } 
                                    type="text" className="form-control" placeholder="First Name" {...register("FirstName")} />
                                </div>
                                {errors.FirstName ? 
                                    <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="MiddleName">MI</label>
                                <div className="input-container">
                                    <input 
                                    maxLength={1} 
                                    value={info.MiddleName} 
                                    onInput={(e) => {
                                        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, ''); 
                                        if (inputValue !== e.target.value || e.nativeEvent.inputType === "deleteContentBackward") {
                                            e.target.value = inputValue; 
                                        }
                                        setInfo({ index: "MiddleName", value: inputValue });
                                    }} 
                                    type="text" className="form-control" placeholder="MI" {...register("MiddleName")} />
                                </div>
                                {errors.MiddleName ? 
                                    <div className="error-message"> Only letters are allowed </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="LastName">Last Name</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={25}
                                        readOnly={Setting.mainSettings.Flow === "DM"} 
                                        value={info.LastName} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split(" ").length - 1) < 2){
                                                        setInfo({ index: "LastName", value: e.target.value.trimLeft() })}
                                                    }
                                                }
                                            }
                                        } 
                                        type="text" className="form-control" placeholder="Last Name" {...register("LastName")} />
                                </div>
                                {errors.LastName ? 
                                    <div className="error-message"> Required field. Please enter only letters and hyphens. </div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="Suffix">Suffix</label>
                                <div className="input-container">
                                    <select
                                    defaultValue={(axios.findParam("suffix")) ? axios.findParam("suffix").toUpperCase() : info.Suffix}
                                    className="form-control"
                                    id="addtl-ch-suffix"
                                    onInput={(e) =>
                                        setInfo({ index: "Suffix", value: e.target.value })
                                    }
                                    {...register("Suffix")}
                                    >
                                    <option value=""></option>
                                    <option value="JR">JR</option>
                                    <option value="SR">SR</option>
                                    <option value="II">II</option>
                                    <option value="III">III</option>
                                    <option value="IV">IV</option>
                                    </select>
                                </div>
                                {errors.Suffix && errors.Suffix.message.map((message) => (
                                    <div className="error-message" key={"Suffix-" + message}> {message} </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="Address1">Home Address <span>(no P.O. boxes)</span></label>
                                <div className="input-container">
                                    <input 
                                        maxLength={26}
                                        value={info.Address1} 
                                        onInput={(e) => {
                                            setInfo({ index: "Address1", value: e.target.value.trimLeft().replace("  ", " ") })}
                                        } 
                                        type="text" className="form-control" placeholder="Address Line 1" {...register("Address1")} />
                                </div>
                                {errors.Address1 && (
                                    <div className="error-message"> {errors.Address1.message} </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="Address2">Address Line 2</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={26}
                                        value={info.Address2} 
                                        onInput={(e) => {
                                            setInfo({ index: "Address2", value: e.target.value.trimLeft().replace("  ", " ") })}
                                        } 
                                        type="text" className="form-control" placeholder="Address Line 2" {...register("Address2")} />
                                </div>
                                {errors.Address2 && (
                                    <div className="error-message"> {errors.Address2.message} </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="City">City</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={18}
                                        value={info.City} 
                                        onInput={(e) =>{ 
                                            if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                if(/^[a-zA-Z ]+$/.test(e.target.value) || e.target.value === ""){
                                                    setInfo({ index: "City", value: e.target.value })}
                                                }
                                            }
                                        } 
                                        type="text" className="form-control" placeholder="City" {...register("City")} />
                                </div>
                                {errors.City && errors.City.message.map((message) => (
                                    <div className="error-message" key={"City-" + message}> {message} </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="State">State</label>
                                <div className="input-container">
                                    <select defaultValue={axios.findParam("state") || info.State} className="form-control" id="State" onInput={(e) => setInfo({ index: "State", value: e.target.value })} {...register("State")}>
                                        <option value=""></option>
                                        {Setting.statesList.map((state) => {
                                            return (
                                                <option value={state.Code} key={state.Code}>
                                                {state.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.State && errors.State.message.map((message) => (
                                    <div className="error-message" key={"State-" + message}> This field is missing. Please select an option. </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="Zip">Zip Code</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={9}
                                        value={info.Zip} 
                                        onInput={(e) => {
                                            
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
                                                    setInfo({ index: "Zip", value: e.target.value })}
                                                }
                                            }
                                        }
                                      
                                        type="text" className="form-control" placeholder="Zip Code" {...register("Zip")} 
                                    />
                                </div>
                                {errors.Zip && (
                                    <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth(MM/DD/YYYY)  </label>
                                <div className="input-container">
                                <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{text.block1.tooltipAge}</Tooltip>}>
                                    <input                                         
                                        value={
                                            moment(info.dob,"YYYY-MM-DD",true).isValid() 
                                            ? 
                                            moment(info.dob,"YYYY-MM-DD",true).format("MM/DD/YYYY") 
                                            : 
                                            masking(info.dob,"XX/XX/XXXX")}                                         
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("/","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo({ 
                                                index: "dob", 
                                                value: 
                                                    moment(e.target.value, "MM/DD/YYYY",true).isValid() 
                                                    ? 
                                                    moment(e.target.value,"MM/DD/YYYY",true).format("YYYY-MM-DD") 
                                                    :
                                                    unmasking(e,"XX/XX/XXXX",info.dob) 
                                                })
                                            }
                                            }
                                        }
                                       
                                        type="text" className="form-control" placeholder="XX/XX/XXXX" {...register("dob")} />
                                        </OverlayTrigger>
                                </div>

                                { errors.dob ? errors.dob && <span className='error-message'> Please enter a valid date of birth. You must be at least 18 years of age or older to apply.  </span> : '' }
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="Email">Email Address</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={100}
                                        value={info.Email} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split("@").length - 1) < 2 ){
                                                        setInfo({ index: "Email", value: e.target.value})
                                                    }
                                                }
                                            }
                                        }} 
                                        onPaste={(e) => {
                                            if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split("@").length - 1) < 2 ){
                                                        setInfo({ index: "Email", value: e.target.value})
                                                    }
                                                }
                                            }
                                        }}
                                        type="text" className="form-control" placeholder="" {...register("Email")} />
                                </div>
                                {errors.Email && (
                                    <div className="error-message" > This field is missing or invalid. Please fill it correctly. </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="ConfirmEmail">Confirm Email Address</label>
                                <div className="input-container">
                                    <input 
                                        maxLength={100}
                                        value={info.ConfirmEmail} 
                                        onInput={(e) => {
                                            if (/[a-zA-Z0-9_\\.@]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                if (/^[a-zA-Z0-9_\\.@]+$/.test(e.target.value) || e.target.value === ""){
                                                    if((e.target.value.split("@").length - 1) < 2){
                                                        setInfo({ index: "ConfirmEmail", value: e.target.value})
                                                    }
                                                }
                                            }
                                        }} 
                                        onPaste={(e) => e.preventDefault()} 
                                        type="text" className="form-control" placeholder="" role="presentation" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" {...register("ConfirmEmail")} 
                                    />
                                </div>
                                {errors.ConfirmEmail && (
                                    <div className="error-message"> {errors.ConfirmEmail.message} </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="ssn">Social Security Number</label>
                                <div className="input-container">
                                    <input 
                                        readOnly={Setting.mainSettings.Flow === "DM"} 
                                        value={masking(info.SSN, "XXX-XX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value)  || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo({ index: "SSN", value:  unmasking(e,"XXX-XX-XXXX", info.SSN) })}
                                            }
                                        } 
                                        type="text" className="form-control" placeholder="xxx-xx-xxxx" {...register("SSN")} />
                                </div>
                                {errors.SSN && (
                                    <div className="error-message"> This field is missing or invalid. Please fill it correctly. </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="MonthlyIncome">Your Total Monthly Income <span className="text-danger">{info.MonthlyIncome >= 12000 ? "Please Ensure that total Monthly Income is Correct!" : ""}</span></label>
                                <div className="input-container">
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip className="mytooltip"  id="monthly-tooltip" >{text.block1.tooltipIncome}</Tooltip>}>
                                        <input 
                                            value={IncomeMask(info.MonthlyIncome)} 
                                            onFocus={() => {ada.sendDYEvent({ name : 'MonthlyIncomeFocusEvent'})}}
                                            onInput={(e) => {
                                                e.preventDefault()
                                                
                                                setCursorPosition(e.target.selectionStart)
                                               
                                                let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("$","").replaceAll(",","") : e.nativeEvent.data;
                                              
                                                if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                    if(e.target.value.replaceAll("$","").replaceAll(",","") <= 99999){
                                                        let value = e.target.value.replaceAll("$","").replaceAll(",","");
                                                        value = (!isNaN(value)) ? parseFloat(value).toFixed(0) : value;
                                                        setInfo({ index: "MonthlyIncome", value : (isNaN(value) ? "" : value) })
                                                    }
                                                }
                                            }} 
                                            onKeyDown={(e) => {
                                                setCursorPosition(e.target.selectionStart)
                                            }}
                                            onKeyUp={(e) => {
                                                if(e.nativeEvent.key === "Backspace" ){
                                                    e.target.setSelectionRange(cursor, cursor);
                                                }
                                            }}
                                            type="text" className="form-control" placeholder="$ 0.00" {...register("MonthlyIncome")} 
                                        />
                                    </OverlayTrigger>
                                </div>
                                {errors.MonthlyIncome && errors.MonthlyIncome.message.map((message) => (
                                    <div className="error-message" key={"MonthlyIncome-" + message}> {message} </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6 col-xs-12">
                            <IncomeCalculator clearMonthlyIncomeError={clearMonthlyIncomeError}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6 offset-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="IncomeSource">Primary Source of Monthly Income</label>
                                <div className="input-container">
                                    <select className="form-control" value={localIncomeSource} onFocus={() => {}} onInput={(e) => setInfo({ index: "IncomeSource", value: e.target.value })} onChange={handleDropdownChange} {...register("IncomeSource")} >
                                        <option value=""> Please select one of the following </option>
                                        <option value="Employed Full-time">Employed Full-time</option>
                                        <option value="Employed Part-time">Employed Part-time</option>
                                        <option value="Self Employed">Self Employed</option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {errors.IncomeSource &&  (
                                    <div className="error-message" > This field is missing. Please select an option. </div>
                                )}
                            </div>  
                            <p>{ text.block1.income }</p>               
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="PrimaryPhone">Mobile Phone Number <small>(10-digits)</small></label>
                                <div className="input-container">
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip   id="phone-tooltip" >{Parser(text.block1.tooltipPhone)}</Tooltip>}>
                                    <input 
                                        value={masking(info.PrimaryPhone, "(XXX) XXX-XXXX")} 
                                        onFocus={() => {ada.sendDYEvent({ name : 'PrimaryPhoneFocusEvent'}); adobe.event('form_checkpoint')}}
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo({ index: "PrimaryPhone", value: unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhone) });
                                                setInfo( { index : 'PrimaryPhoneSms', value : unmasking(e, "(XXX) XXX-XXXX", info.PrimaryPhoneSms)})
                                            }
                                        }} 
                                        
                                        type="text" className="form-control" placeholder="(XXX) XXX-XXXX" {...register("PrimaryPhone")} 
                                    />
                                    </OverlayTrigger>
                                </div>
                                {errors.PrimaryPhone &&  (
                                    <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div className="form-group">
                                <label htmlFor="SecondaryPhone">Secondary phone number</label>
                                <div className="input-container">                                   
                                    <input 
                                        value={masking(info.SecondaryPhone, "(XXX) XXX-XXXX")} 
                                        onInput={(e) => {
                                            let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value.replaceAll("-","").replaceAll("(","").replaceAll(")","") : e.nativeEvent.data;
                                            if (/[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                setInfo({ index: "SecondaryPhone", value: unmasking(e, "(XXX) XXX-XXXX", info.SecondaryPhone) });
                                                setInfo( { index : 'SecondaryPhoneSms', value : unmasking(e, "(XXX) XXX-XXXX", info.SecondaryPhoneSms)})
                                            }
                                            
                                        }} 
                                      
                                        type="text" className="form-control" placeholder="(XXX) XXX-XXXX" {...register("SecondaryPhone")} 
                                    />                                  
                                </div>
                                {errors.SecondaryPhone &&  (
                                    <div className="error-message">This field is missing or invalid. Please fill it correctly.</div>
                                )}
                            </div>
                        </div>
                    </div>  
                   
                    
                    {(info.State === "WI") && (
                        <SpouseForm />
                    )}
                    
                    <div className="row mt-3">
                        <div className="col-6">
                            <div>Do you have an active checking account or debit card?</div>
                            {errors.HasCheckingAccount && errors.HasCheckingAccount.message.map((message) => (
                                <div className="error-message" key={"HasCheckingAccount-" + message}> This field is missing. Please select an option. </div>
                            ))}
                        </div>
                        <div className="col-6">
                            <div className="radioGroup">
                                <div className="radio-btn">
                                    <input type="radio" checked={info.HasCheckingAccount === "true"} value={"true"} {...register("HasCheckingAccount")} onClick={(e) => setInfo({ index: "HasCheckingAccount", value: e.target.value, })}/>
                                    <label className="ml-2">Yes</label>
                                </div>
                                <div className="radio-btn">
                                    <input type="radio" checked={info.HasCheckingAccount === "false"} value={"false"} {...register("HasCheckingAccount")} onClick={(e) => setInfo({ index: "HasCheckingAccount", value: e.target.value, })}/>
                                    <label className="ml-2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {checkIfFieldIsActive("cashIntent") ? (
                        <div className="row mt-3">
                            <div className="col-6">
                                <div>Do you intend to use this card for cash advances?</div>
                                {errors.cashIntent && errors.cashIntent.message.map((message) => (
                                    <div className="error-message"><p>{message}</p> </div>
                                ))}
                            </div>
                            <div className="col-6">
                                <div className="radioGroup">
                                    <div className="radio-btn">
                                        <input type="radio" checked={info.cashIntent === "true"} value={"true"} {...register("cashIntent")} onClick={(e) => setInfo({ index: "cashIntent", value: e.target.value, })}/>
                                        <label className="ml-2">Yes</label>
                                    </div>
                                    <div className="radio-btn">
                                        <input type="radio" checked={info.cashIntent === "false"} value={"false"} {...register("cashIntent")} onClick={(e) => setInfo({ index: "cashIntent", value: e.target.value, })}/>
                                        <label className="ml-2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <></> }
                        <div className="row mt-3">
                        <div className="col-6">
                            <div> Would you like an additional card?</div>
                            {errors.hasAdditionalCardholder &&  (
                                <div className="error-message"><p className="pl-0 font12">This field is missing. Please select an option.</p></div>
                            )}
                        </div>
                        <div className="col-6">
                            <div className="radioGroup">
                                <div className="radio-btn">
                                    <input type="radio" checked={info.hasAdditionalCardholder === "true"} value={"true"} {...register("hasAdditionalCardholder")} onClick={(e) => setInfo({ index: "hasAdditionalCardholder", value: e.target.value, })}/>
                                    <label className="ml-2">Yes</label>
                                </div>
                                <div className="radio-btn">
                                    <input type="radio" checked={info.hasAdditionalCardholder === "false"} value={"false"} {...register("hasAdditionalCardholder")} onClick={(e) => setInfo({ index: "hasAdditionalCardholder", value: e.target.value, })}/>
                                    <label className="ml-2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" >
                        {info.hasAdditionalCardholder === "true" && ( 
                            <>  
                                <div className="col-12">
                                    <div className="smallerText">
                                        {text.block1.disclaimerAdditionalCardholder}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="additionalCardholderFirstName">First Name</label>
                                        <div className="input-container">
                                            <input 
                                                maxLength={17}
                                                value={info.additionalCardholderFirstName} 
                                                onInput={(e) => {
                                                    if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                        if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                            if((e.target.value.split(" ").length - 1) < 2){
                                                                setInfo({ index: "additionalCardholderFirstName", value: e.target.value.trimLeft() })}
                                                            }
                                                        }
                                                    }
                                                } 
                                                type="text" className="form-control" placeholder="First Name" 
                                                {...register(
                                                    "additionalCardholderFirstName"
                                                )} />
                                        </div>                                       
                                        {errors.additionalCardholderFirstName && errors.additionalCardholderFirstName.message.map((message) => (
                                            <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                                        ))}
                                       
                                    </div>
                                </div>
                                <div className="col-lg-2 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="additionalCardholderMI">MI</label>
                                        <div className="input-container">
                                            <input 
                                                maxLength={1}
                                                value={info.additionalCardholderMI} 
                                                onInput={(e) => {
                                                    if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward") {
                                                        setInfo({ index: "additionalCardholderMI", value: e.target.value })}
                                                    }
                                                    
                                                } 
                                                type="text" className="form-control" placeholder="MI" {...register("additionalCardholderMI")} />
                                        </div>
                                        {errors.additionalCardholderMI && errors.additionalCardholderMI.message.map((message) => (
                                            <div className="error-message" key={"additionalCardholderMI-" + message}> {message} </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="additionalCardholderLastName">Last Name</label>
                                        <div className="input-container">
                                            <input 
                                                maxLength={25}
                                                value={info.additionalCardholderLastName} 
                                                onInput={(e) => {
                                                    if (/[a-zA-Z ]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                                                        if(/^[a-zA-Z- ]+$/.test(e.target.value) || e.target.value === ""){
                                                            if((e.target.value.split(" ").length - 1) < 2){
                                                                setInfo({ index: "additionalCardholderLastName", value: e.target.value.trimLeft() })}
                                                            }
                                                        }
                                                    }
                                                } 
                                                type="text" className="form-control" placeholder="Last Name" {...register("additionalCardholderLastName")} />
                                        </div>
                                        {errors.additionalCardholderLastName && errors.additionalCardholderLastName.message.map((message) => (
                                            <div className="error-message" key={"additionalCardholderLastName-" + message}> Required field. Please enter only letters and hyphens. </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="additionalCardholderSuffix">Suffix</label>
                                        <select className="form-control" id="additionalCardholderSuffix" {...register("additionalCardholderSuffix") }  onInput={(e) => setInfo({ index: "additionalCardholderSuffix", value: e.target.value })}>
                                            <option value=""></option>
                                            <option value="JR">JR</option>
                                            <option value="SR">SR</option>
                                            <option value="II">II</option>
                                            <option value="III">III</option>
                                            <option value="IV">IV</option>
                                        </select>
                                    </div>
                                    {errors.additionalCardholderSuffix &&
                                    errors.additionalCardholderSuffix.message.map((message) => (
                                        <div className="error-message" key={"additionalCardholderSuffix-" + message}> {message} </div>
                                    ))}
                                </div>
                                
                            </>
                            
                        )}
                    </div>
                </div>
    );
}

export default UserDataForm;
